export const darkTheme = {
    bg: "#1C1C27",
    bgLight: "#1C1E27",
    primary: "#854CE6",
    text_primary: "#F2F3F4",
    text_secondary: "#b1b2b3",
    card: "#171721",
    card_light: "#191924",
    button: "#854CE6",
    white: "#FFFFFF",
    black: "#000000",
  }
  
  export const lightTheme = {
    bg: "#FFFFFF",
    bgLight: "#f0f0f0",
    primary: "#be1adb",
    text_primary: "#111111",
    text_secondary: "#48494a",
    card: "#FFFFFF",
    button: "#5c5b5b",
  }
  
  // Tambahkan properti colors ke masing-masing tema
  darkTheme.colors = {
    primary1: darkTheme.primary,
    background1: darkTheme.bg,
    button: darkTheme.button,
    background2: darkTheme.bgLight,
    text: darkTheme.text_secondary,
    text1: darkTheme.text_primary,
    text2: darkTheme.text_secondary,
    text3: darkTheme.text_secondary,
    footerBackground: "#00012B"
  }
  
  lightTheme.colors = {
    primary1: lightTheme.primary,
    background1: lightTheme.bg,
    button: lightTheme.button,
    background2: lightTheme.bgLight,
    text: lightTheme.text_secondary,
    text1: lightTheme.text_primary,
    text2: lightTheme.text_secondary,
    text3: lightTheme.text_secondary,
    footerBackground: "#f0f0f0"
  }
  
  // Tambahkan fonts dan breakpoints ke kedua tema
  const commonProperties = {
    fonts: {
      title: "Space Grotesk, sans-serif",
      main: "Space Grotesk, sans-serif"
    },
    breakpoints: {
      sm: 'screen and (max-width: 640px)',
      md: 'screen and (max-width: 768px)',
      lg: 'screen and (max-width: 1024px)',
      xl: 'screen and (max-width: 1280px)'
    },
  }
  
  Object.assign(darkTheme, commonProperties);
  Object.assign(lightTheme, commonProperties);