import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { useTheme } from 'styled-components';
import ScrollToTop from './ScrollToTop';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();

  const modernLogoStyle = {
    display: "flex",
    alignItems: "center",
    color: theme?.colors?.text1 || "white",
    marginBottom: '20px',
    cursor: 'pointer',
    fontFamily: theme?.fonts?.title || "'Space Grotesk', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
  }

  const bracketStyle = {
    color: theme?.colors?.primary1 || "#854CE6",
    marginRight: '4px',
  }

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to='/' onClick={handleLogoClick}>
            <a href="/" style={modernLogoStyle} onClick={handleLogoClick}>
              <span style={bracketStyle}>&lt;</span>
              <Span style={{color: theme?.colors?.text1 || "white"}}>RI</Span>
              <Span style={{color: theme?.colors?.primary1 || "#854CE6"}}>PORT</Span>
              <span style={{...bracketStyle, marginLeft: '4px'}}>/&gt;</span>
            </a>
          </NavLogo>
          <MobileIcon>
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </MobileIcon>
          <NavItems>
            <NavLink href="#about">About</NavLink>
            <NavLink href='#skills'>Skills</NavLink>
            <NavLink href='#experience'>Experience</NavLink>
            {/* <NavLink href='#projects'>Projects</NavLink> */}
            <NavLink href='#education'>Education</NavLink>
          </NavItems>
          <ButtonContainer>
            <GitHubButton href={Bio.github} target="_blank">Github Profile</GitHubButton>
          </ButtonContainer>
          {
            isOpen &&
            <MobileMenu isOpen={isOpen}>
              <MobileLink href="#about" onClick={() => setIsOpen(!isOpen)}>About</MobileLink>
              <MobileLink href='#skills' onClick={() => setIsOpen(!isOpen)}>Skills</MobileLink>
              <MobileLink href='#experience' onClick={() => setIsOpen(!isOpen)}>Experience</MobileLink>
              {/* <MobileLink href='#projects' onClick={() => setIsOpen(!isOpen)}>Projects</MobileLink> */}
              <MobileLink href='#education' onClick={() => setIsOpen(!isOpen)}>Education</MobileLink>
              <GitHubButton style={{padding: '10px 16px',background: theme?.colors?.primary1 || "#854CE6", color: 'white',width: 'max-content'}} href={Bio.github} target="_blank">Github Profile</GitHubButton>
            </MobileMenu>
          }
        </NavbarContainer>
      </Nav>
      <ScrollToTop />
    </>
  )
}

export default Navbar